import React from 'react'

const defaultData = {
	strings: {},
	settings: {
		withoutInstallationOption: false,
		installationProductSku: '',
		expressInstallationProductSku: '',
		greenDeductionBlacklist: [],
		garo: {
			installationAddons: [],
			installationProductSku: '',
			products: []
		}
	}
}

export const CheckoutContext = React.createContext(defaultData)
