import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import {currencyFormatter} from 'utils/Utils'

export class InstallationListItem extends Component {
	render() {
		return (
			<li className='media-product-list-item'>
				<Form.Check type='radio'>
					<Form.Check.Input
						id={this.props.product.sku}
						type='radio'
						name='installation'
						checked={this.props.checked}
						onChange={() => this.props.onSelect(this.props.product.sku)}
					/>
					<Form.Check.Label htmlFor={this.props.product.sku}>
						<div className='product-information'>
							<h5>{this.props.product.name}</h5>
							{this.props.product.description && (
								<div className='product-description'>
									{this.props.product.description}
								</div>
							)}
						</div>

						<div className='installation-price'>
							{`+ ${currencyFormatter.format(this.props.product.bundlePrice)}`}
						</div>
					</Form.Check.Label>
				</Form.Check>
			</li>
		)
	}
}

export default InstallationListItem
