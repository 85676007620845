import React, {Component} from 'react'
import BootstrapAccordion from 'react-bootstrap/Accordion'
import {CheckoutContext} from 'contexts/CheckoutContext'
import AccordionCard from 'components/accordion/AccordionCard'
import Shop from 'components/shop/Shop'
import Cart from 'components/cart/Cart'
import Installation from 'components/installation/Installation'
import InstallationProfile from 'components/installationprofile/InstallationProfile'
import CustomerProfile from 'components/customerprofile/CustomerProfile'
import {hasInstallableLineItems, requiresInstallationProfile} from 'utils/Utils'

export const STEP_SHOP = 'step_shop'
export const STEP_INSTALLATION = 'step_installation'
export const STEP_CART = 'step_cart'
export const STEP_CUSTOMER = 'step_customer'
export const STEP_INSTALLATION_PROFILE = 'step_installation_profile'
const STEPS = [
	STEP_SHOP,
	STEP_INSTALLATION,
	STEP_CART,
	STEP_CUSTOMER,
	STEP_INSTALLATION_PROFILE
]

export class Accordion extends Component {
	constructor(props) {
		super(props)
		this.defaultState = {
			activeStep: STEP_SHOP,
			enabledSteps: [STEP_SHOP],
			completedSteps: []
		}
		this.state = this.defaultState
	}

	componentDidMount() {
		this.deduceState()
	}

	componentDidUpdate(prevProps) {
		//If cart items have changed a state change may be required
		if (this.props.cart.lineItems.length !== prevProps.cart.lineItems.length) {
			if (!this.props.cart.lineItems.length) {
				//An empty cart should bring the user back to the shop step
				this.setState(this.defaultState)
			} else {
				//A non-empty cart means the cart step be enabled
				const enabledSteps = hasInstallableLineItems(this.props.cart)
					? [STEP_SHOP, STEP_INSTALLATION, STEP_CART]
					: [STEP_SHOP, STEP_CART]

				this.setState({
					enabledSteps
				})
			}
		}
		//If we have added to cart through param we should always to deduce state
		if (
			this.props.didAddToCartThroughParam !==
				prevProps.didAddToCartThroughParam &&
			this.props.didAddToCartThroughParam
		) {
			this.setState({
				activeStep: STEP_CART
			})
		}
	}

	deduceState(desiredStep) {
		let activeStep, completedSteps, enabledSteps

		const hasCartItems = Boolean(this.props.cart.lineItems.length)
		const hasCustomerProfile = Boolean(this.props.cart.customer)
		const hasInstallationProfile = Boolean(this.props.cart.installation)

		if (hasCartItems) {
			//If we have items in cart, assume shop and cart step completed
			activeStep = STEP_CART
			enabledSteps = [STEP_SHOP, STEP_CART, STEP_CUSTOMER]
			completedSteps = [STEP_SHOP, STEP_CART]
		}
		if (hasCartItems && hasInstallableLineItems(this.props.cart)) {
			//If we have installable items in cart, enable installation step
			activeStep = STEP_CART
			enabledSteps = [STEP_SHOP, STEP_INSTALLATION, STEP_CART, STEP_CUSTOMER]
			completedSteps = [STEP_SHOP, STEP_INSTALLATION, STEP_CART]
		}
		if (hasCartItems && hasCustomerProfile) {
			//If we also have a customer profile, assume all steps except installation completed
			activeStep = STEP_INSTALLATION_PROFILE
			enabledSteps = STEPS
			completedSteps = [STEP_SHOP, STEP_INSTALLATION, STEP_CART, STEP_CUSTOMER]
			//If the order doesn't require an installation profile, we are done now.
			if (
				!requiresInstallationProfile(
					this.props.cart,
					this.context.settings.installationProductSku,
					this.context.settings.expressInstallationProductSku,
					this.context.settings.garo.installationProductSku
				) &&
				desiredStep === STEP_INSTALLATION_PROFILE
			) {
				this.props.onCompleteCheckout()
			}
		}
		if (hasCartItems && hasCustomerProfile && hasInstallationProfile) {
			//If we also have an installation profile, assume all steps completed
			//TODO: Determine if this cart can ever exist as this should mean the checkout has completed
			activeStep = STEP_INSTALLATION_PROFILE
			enabledSteps = completedSteps = STEPS
			this.props.onCompleteCheckout()
		}

		// Fall back if the desired step is not among the available steps
		if (!desiredStep || !enabledSteps.includes(desiredStep)) {
			desiredStep = null
		}

		this.setState({
			activeStep: desiredStep || activeStep || this.defaultState.activeStep,
			enabledSteps: enabledSteps || this.defaultState.enabledSteps,
			completedSteps: completedSteps || this.defaultState.completedSteps
		})
	}

	completeStep = (stepKey) => {
		this.deduceState(STEPS[STEPS.indexOf(stepKey) + 1])
	}

	showStep = (stepKey) => {
		if (!this.state.activeStep !== stepKey) {
			this.setState({
				activeStep: stepKey
			})
		}
	}

	render() {
		return (
			<BootstrapAccordion
				defaultActiveKey={
					this.props.didAddToCartThroughParam ? STEPS[1] : STEPS[0]
				}
				activeKey={this.state.activeStep}>
				<AccordionCard
					eventKey={STEP_SHOP}
					accordionState={this.state}
					onHeaderClick={this.showStep}
					title={this.context.strings.accordionHeaderShop}>
					<Shop
						cart={this.props.cart}
						completeStep={this.completeStep}
						onShopQueryCompleted={this.props.onShopQueryCompleted}
					/>
				</AccordionCard>
				<AccordionCard
					eventKey={STEP_INSTALLATION}
					accordionState={this.state}
					onHeaderClick={this.showStep}
					title={this.context.strings.accordionHeaderInstallation}>
					<Installation
						cart={this.props.cart}
						completeStep={this.completeStep}
					/>
				</AccordionCard>
				<AccordionCard
					eventKey={STEP_CART}
					accordionState={this.state}
					onHeaderClick={this.showStep}
					title={this.context.strings.accordionHeaderCart}
					badge={this.props.cart.lineItems.length}>
					<Cart cart={this.props.cart} completeStep={this.completeStep} />
				</AccordionCard>
				<AccordionCard
					eventKey={STEP_CUSTOMER}
					accordionState={this.state}
					onHeaderClick={this.showStep}
					title={this.context.strings.accordionHeaderPersonalDetails}>
					<CustomerProfile
						cart={this.props.cart}
						completeStep={this.completeStep}
					/>
				</AccordionCard>
				{requiresInstallationProfile(
					this.props.cart,
					this.context.settings.installationProductSku,
					this.context.settings.expressInstallationProductSku,
					this.context.settings.garo.installationProductSku
				) && (
					<AccordionCard
						eventKey={STEP_INSTALLATION_PROFILE}
						accordionState={this.state}
						onHeaderClick={this.showStep}
						title={this.context.strings.accordionHeaderInstallationProfile}>
						<InstallationProfile
							cart={this.props.cart}
							completeStep={this.completeStep}
						/>
					</AccordionCard>
				)}
			</BootstrapAccordion>
		)
	}
}

Accordion.contextType = CheckoutContext

export default Accordion
