import React, {Component} from 'react'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {
	CUSTOMER_TYPE_PRIVATE,
	CUSTOMER_TYPE_ORGANIZATION
} from 'components/customerprofile/CustomerProfile'

export class CustomerTypeSwitch extends Component {
	render() {
		return (
			<ToggleButtonGroup
				type='radio'
				size='sm'
				name='options'
				value={this.props.value}
				onChange={this.props.onChange}>
				<ToggleButton
					variant='outline-bee-navy'
					key={CUSTOMER_TYPE_PRIVATE}
					value={CUSTOMER_TYPE_PRIVATE}
					disabled={this.props.disabled}>
					{this.context.strings.formLabelPrivate}
				</ToggleButton>
				<ToggleButton
					variant='outline-bee-navy'
					key={CUSTOMER_TYPE_ORGANIZATION}
					value={CUSTOMER_TYPE_ORGANIZATION}
					disabled={this.props.disabled}>
					{this.context.strings.formLabelOrganization}
				</ToggleButton>
			</ToggleButtonGroup>
		)
	}
}

CustomerTypeSwitch.contextType = CheckoutContext

export default CustomerTypeSwitch
