import React, {Component} from 'react'
import {loader} from 'graphql.macro'
import ApolloClient from 'apollo-boost'
import {ApolloProvider} from 'react-apollo'
import {Query} from 'react-apollo'
import ResizeObserver from 'resize-observer-polyfill'
import 'url-search-params-polyfill'
import {CheckoutContext} from 'contexts/CheckoutContext'
import Accordion from 'components/accordion/Accordion'
import OrderConfirmation from 'components/orderconfirmation/OrderConfirmation'
import {canAddToCart, findProductBySKU} from 'utils/Utils'
import LocaleStrings from 'assets/locale/sv.json'
import OrderLimitModal from './shop/OrderLimitModal'

const ENTER_STORE_MUTATION = loader('queries/enterStore.gql')
const COMPLETE_CHECKOUT_MUTATION = loader('queries/completeCheckout.gql')
const UPDATE_CART_MUTATION = loader('queries/updateCart.gql')
const CART_QUERY = loader('queries/cart.gql')

const apolloClient = new ApolloClient({
	uri: process.env.REACT_APP_GQL_URI,
	request: async (operation) => {
		const token = localStorage.getItem('bee-checkout-token')
		operation.setContext({
			headers: {
				authorization: token ? `Bearer ${token}` : ''
			}
		})
	}
})

export class App extends Component {
	constructor(props) {
		super(props)

		const url = new URL(window.location)
		this._urlParams = new URLSearchParams(url.search)

		this.state = {
			checkoutCompleted: false,
			settings: {
				withoutInstallationOption: false,
				installationProductSku: null,
				expressInstallationProductSku: null,
				greenDeductionBlacklist: [],
				garo: {
					installationAddons: [],
					installationProductSku: null,
					products: []
				}
			},
			token: null,
			showOrderLimitModal: false,
			orderLimitProductCategory: null,
			strings: LocaleStrings.strings,
			didAddToCartThroughParam: false
		}
	}

	componentDidMount() {
		apolloClient
			.mutate({
				mutation: ENTER_STORE_MUTATION,
				variables: {department: this._urlParams.get('department') || null}
			})
			.then((result) => {
				const {enterStore} = result.data

				this.setState(
					{
						token: enterStore.token,
						settings: {
							...this.state.settings,
							withoutInstallationOption:
								enterStore.settings.withoutInstallationOption,
							installationProductSku:
								enterStore.settings.installationProductSku,
							expressInstallationProductSku:
								enterStore.settings.expressInstallationProductSku,
							greenDeductionBlacklist:
								enterStore.settings.greenDeductionBlacklist,
							garo: {
								installationAddons: enterStore.settings.garo.installationAddons,
								installationProductSku:
									enterStore.settings.garo.installationProductSku,
								products: enterStore.settings.garo.products
							}
						}
					},
					() => {
						localStorage.setItem('bee-checkout-token', this.state.token)
					}
				)
			})
			.catch((error) => {
				console.log(error)
			})

		const ro = new ResizeObserver((entries, observer) => {
			for (const entry of entries) {
				if (entry.contentRect.height > 0) {
					window.parent.postMessage(
						['setHeight', entry.contentRect.height],
						'*'
					)
				}
			}
		})

		ro.observe(document.body)
	}

	onCompleteCheckout = () => {
		apolloClient
			.mutate({
				mutation: COMPLETE_CHECKOUT_MUTATION,
				variables: {acceptTerms: true}
			})
			.then(() => {
				this.setState({
					checkoutCompleted: true
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	onShopQueryCompleted = (shopData) => {
		const {_urlParams} = this

		//Check if a product SKU param has been passed
		if (_urlParams.has('bc-add-product')) {
			//Check if a product with the passed SKU exists
			let product = findProductBySKU(
				shopData.products,
				_urlParams.get('bc-add-product')
			)
			if (!product) return // No product with matching SKU was found
			let cart = apolloClient.readQuery({query: CART_QUERY}).cart // Read the current cart from the cache
			if (canAddToCart(cart, product)) {
				apolloClient
					.mutate({
						mutation: UPDATE_CART_MUTATION,
						variables: {
							sku: product.sku,
							quantity: 1,
							withInstallation: false,
							withExpressInstallation: false
						}
					})
					.then((result) => {
						//Since we are not in an mutation update function we can't call the updateCartCache util method here, see https://github.com/apollographql/apollo-client/issues/3909
						apolloClient.writeQuery({
							query: CART_QUERY,
							data: {cart: result.data.updateCart}
						})
						this.setState({didAddToCartThroughParam: true})
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				this.showOrderLimitModal(true, product.category)
			}
		}
	}

	showOrderLimitModal = (show, productCategory) => {
		this.setState({
			showOrderLimitModal: show,
			orderLimitProductCategory: productCategory
		})
	}

	render() {
		return this.state.token ? (
			<ApolloProvider client={apolloClient}>
				<CheckoutContext.Provider
					value={{settings: this.state.settings, strings: this.state.strings}}>
					<Query query={CART_QUERY}>
						{({loading, error, data}) => {
							if (loading || error) return null
							return this.state.checkoutCompleted ? (
								<OrderConfirmation cart={data.cart} />
							) : (
								<Accordion
									cart={data.cart}
									onShopQueryCompleted={this.onShopQueryCompleted}
									onCompleteCheckout={this.onCompleteCheckout}
									didAddToCartThroughParam={this.state.didAddToCartThroughParam}
								/>
							)
						}}
					</Query>
					<OrderLimitModal
						show={this.state.showOrderLimitModal}
						productCategory={this.state.orderLimitProductCategory}
						onHide={() => this.showOrderLimitModal(false)}
					/>
				</CheckoutContext.Provider>
			</ApolloProvider>
		) : null
	}
}

export default App
