import React, {Component} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

export class AccordionHeader extends Component {
	render() {
		return (
			<Card.Header>
				<Accordion.Toggle
					eventKey={this.props.eventKey}
					className={this.props.completed ? 'completed' : ''}
					as={Button}
					href='#'
					block
					variant='accordion-header'
					active={this.props.active}
					disabled={!this.props.enabled}
					onClick={(e) => this.props.onHeaderClick(this.props.eventKey)}>
					{this.props.title}
					{this.props.badge !== 0 && (
						<Badge variant='bee-dragonfruit' className='ml-2 badge-circular'>
							{this.props.badge}
						</Badge>
					)}
				</Accordion.Toggle>
			</Card.Header>
		)
	}
}

export default AccordionHeader
