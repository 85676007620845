import React, {Component} from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Fade from 'react-bootstrap/Fade'

export class LoadingState extends Component {
	render() {
		return (
			<div
				className={
					this.props.loading ? 'loading-state loading' : 'loading-state idle'
				}>
				{this.props.children}
				<Fade in={this.props.loading} unmountOnExit={true}>
					<div className='loading-state-overlay'>
						<Spinner animation='grow' variant='bee-dragonfruit' />
					</div>
				</Fade>
			</div>
		)
	}
}

export default LoadingState
