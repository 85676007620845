import React, {Component} from 'react'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {Container, Row, Col, Table} from 'react-bootstrap'
import {currencyFormatter} from 'utils/Utils'

export class OrderConfirmation extends Component {
	getFormattedOrderDate() {
		let today = new Date()
		const dd = today.getDate()
		const mm = today.getMonth()
		const yyyy = today.getFullYear()

		const months = [
			'Januari',
			'Februari',
			'Mars',
			'April',
			'Maj',
			'Juni',
			'Juli',
			'Augusti',
			'September',
			'Oktober',
			'November',
			'December'
		]

		return (today = dd + ' ' + months[mm] + ' ' + yyyy)
	}

	render() {
		const orderDate = this.getFormattedOrderDate()
		const orderHasDiscount = this.props.cart.discountTotal ? true : false

		return (
			<div className='order-confirmation'>
				<Container className='section'>
					<h3>What the buzz is about!</h3>
					<p>
						<strong>
							Tack för ditt köp, {this.props.cart.customer.address.name}!
						</strong>
					</p>
					<p className='order-confirmation__text'>
						Vi har nu tagit emot din order. Leveranstiden beror på vilken
						produkt du har beställt. Har du beställt ett laddkort skickas detta
						normalt första vardagen efter din beställning. Laddboxar och
						laddkablar skickas inom två veckor efter din beställning. Har du
						beställt en laddbox med installation kommer laddboxen att levereras
						i samband med installationen. Ledtid för installation av laddbox är
						för närvarande 7 veckor.
					</p>
				</Container>
				<Container className='section'>
					<h4>Orderdetaljer</h4>
					<Row>
						<Col>
							<dl>
								<dt>Beställningsdatum</dt>
								<dd>{orderDate}</dd>
							</dl>
							<dl>
								<dt>Beställningsmetod</dt>
								<dd>Faktura</dd>
							</dl>
						</Col>
						<Col>
							<dl>
								<dt>Dina uppgifter</dt>
								<address>
									{this.props.cart.customer.address.name}
									<br />
									{this.props.cart.customer.address.addressline1}
									<br />
									{this.props.cart.customer.address.zip}
									<br />
									{this.props.cart.customer.address.city}
									<br />
								</address>
								<address>
									{this.props.cart.customer.address.email}
									<br />
									{this.props.cart.customer.address.cellphone}
									<br />
								</address>
							</dl>
						</Col>
					</Row>
				</Container>
				<Container className='section'>
					<h4>Din beställning</h4>
					<Table>
						<thead>
							<tr>
								<th>Produkt</th>
								<th className='order-confirmation--desktop'>Antal</th>
								{orderHasDiscount && (
									<th className='order-confirmation--desktop'>Rabatt</th>
								)}
								<th>Pris</th>
							</tr>
						</thead>
						<tbody>
							{this.props.cart.lineItems.map((item, i) => {
								return (
									<tr
										key={i}
										data-product-id={item.product.sku}
										data-product-name={item.product.name}
										data-product-quantity={item.quantity}
										data-product-price={item.unitPrice}>
										<td>
											{item.product.name}
											<span className='order-confirmation--mobile'>
												{item.quantity} st
											</span>
										</td>
										<td className='order-confirmation--desktop'>
											{item.quantity} st
										</td>
										{orderHasDiscount && (
											<td className='order-confirmation--desktop order-confirmation__discount-total'>
												{item.discountTotal > 0
													? '-' + currencyFormatter.format(item.discountTotal)
													: ''}
											</td>
										)}
										<td>{currencyFormatter.format(item.total)}</td>
									</tr>
								)
							})}
						</tbody>
						<tfoot>
							<tr
								data-summary-fee={
									this.props.cart.serviceFee != null
										? currencyFormatter.format(this.props.cart.serviceFee.total)
										: '0 kr'
								}>
								{orderHasDiscount && (
									<td className='order-confirmation--desktop'></td>
								)}
								<td className='order-confirmation--desktop'></td>
								<td>{this.context.strings.serviceFee}</td>
								<td>
									{this.props.cart.serviceFee != null
										? currencyFormatter.format(this.props.cart.serviceFee.total)
										: '0 kr'}
								</td>
							</tr>
							<tr data-summary-sub-total={this.props.cart.subTotal}>
								{orderHasDiscount && (
									<td className='order-confirmation--desktop'></td>
								)}
								<td className='order-confirmation--desktop'></td>
								<td>{this.context.strings.cartSummarySubTotal}</td>
								<td>{currencyFormatter.format(this.props.cart.subTotal)}</td>
							</tr>
							<tr data-summary-vat={this.props.cart.vat}>
								{orderHasDiscount && (
									<td className='order-confirmation--desktop'></td>
								)}
								<td className='order-confirmation--desktop'></td>
								<td>{this.context.strings.cartSummaryVAT}</td>
								<td>{currencyFormatter.format(this.props.cart.vat)}</td>
							</tr>
							<tr data-summary-total={this.props.cart.total}>
								{orderHasDiscount && (
									<td className='order-confirmation--desktop'></td>
								)}
								<td className='order-confirmation--desktop'></td>
								<th>{this.context.strings.cartSummaryTotal}</th>
								<th>{currencyFormatter.format(this.props.cart.total)}</th>
							</tr>
						</tfoot>
					</Table>
				</Container>
				<div className='order-confirmation__footer'>
					<p>
						Har du några funderingar kring din beställning är du alltid
						välkommen att kontakta vår kundservice på{' '}
						<a href='mailto:kundservice@mer.eco'>kundservice@mer.eco</a> eller{' '}
						<a href='tel:+46774433900'>077 44 33 900</a>.
					</p>
				</div>
			</div>
		)
	}
}

OrderConfirmation.contextType = CheckoutContext

export default OrderConfirmation
