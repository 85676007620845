import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class OrderLimitModal extends Component {
	render() {
		return (
			<Modal
				size='sm'
				show={this.props.show}
				onHide={this.props.onHide}
				centered>
				<Modal.Header>
					<Modal.Title>{this.context.strings.orderLimitModalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.context.strings.orderLimitModalBody.replace(
						'%productCategory%',
						this.props.productCategory ? this.props.productCategory.name : ''
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='bee-action-primary'
						size='sm'
						onClick={this.props.onHide}>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

OrderLimitModal.contextType = CheckoutContext

export default OrderLimitModal
