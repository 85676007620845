import React, {Component} from 'react'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {FILTER_VIEW_ALL} from 'components/shop/Shop'

export class ProductCategoryFilter extends Component {
	render() {
		return (
			<ToggleButtonGroup
				type='radio'
				size='sm'
				name='options'
				className='btn-group-product-category-filter'
				defaultValue={this.props.filterValue}
				onChange={this.props.onChange}>
				<ToggleButton
					variant='outline-bee-navy'
					key={FILTER_VIEW_ALL}
					value={FILTER_VIEW_ALL}>
					{this.context.strings.viewAll}
				</ToggleButton>
				{this.props.productCategories.map((productCategory) => (
					<ToggleButton
						variant='outline-bee-navy'
						key={productCategory.id}
						value={productCategory.id}>
						{productCategory.name}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		)
	}
}

ProductCategoryFilter.contextType = CheckoutContext

export default ProductCategoryFilter
