import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {Mutation} from 'react-apollo'
import {loader} from 'graphql.macro'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {
	updateCartCache,
	createCustomerProfileInput,
	requiresInstallationProfile
} from 'utils/Utils'
import {CustomerTypeSwitch} from 'components/customerprofile/CustomerTypeSwitch'
import TextInput from 'components/common/TextInput'
import {STEP_CUSTOMER} from 'components/accordion/Accordion'
import LoadingState from 'components/common/LoadingState'
import CountrySelect from 'components/common/CountrySelect'
import AcceptanceCheckbox from 'components/common/AcceptanceCheckbox'
import FormErrorAlert from 'components/common/FormErrorAlert'

const ATTACH_CUSTOMER_PROFILE_MUTATION = loader(
	'queries/attachCustomerProfile.gql'
)

export const CUSTOMER_TYPE_PRIVATE = 'PRIVATE'
export const CUSTOMER_TYPE_ORGANIZATION = 'ORGANIZATION'

export class CustomerProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			customerType: this.props.cart.customer
				? this.props.cart.customer.type
				: CUSTOMER_TYPE_PRIVATE,
			validated: false,
			selectedCountry: this.props.cart.customer
				? this.props.cart.customer.address.countryCode
				: 'SE'
		}
	}

	getGovIdentifierLabel() {
		switch (this.state.customerType) {
			case CUSTOMER_TYPE_PRIVATE:
				return this.context.strings.formLabelGovIdentifierPrivate
			case CUSTOMER_TYPE_ORGANIZATION:
				return this.context.strings.formLabelGovIdentifierOrganization
			default:
				return null
		}
	}

	getGovIdentifierPattern() {
		switch (this.state.customerType) {
			case CUSTOMER_TYPE_PRIVATE:
				return '^(19|20)?[0-9]{2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$'
			case CUSTOMER_TYPE_ORGANIZATION:
				return '(\\d{6}[-]\\d{4})|(\\d{10})'
			default:
				return null
		}
	}

	getGovIdentifierPlaceholder() {
		switch (this.state.customerType) {
			case CUSTOMER_TYPE_PRIVATE:
				return 'ÅÅÅÅMMDD-NNNN'
			case CUSTOMER_TYPE_ORGANIZATION:
				return 'NNNNNN-NNNN'
			default:
				return null
		}
	}

	onCountryChange = (evt) => {
		this.setState({
			selectedCountry: evt.target.value
		})
	}

	onCustomerTypeChange = (customerType) => {
		this.setState({
			customerType: customerType
		})
	}

	onFormSubmit = (evt, mutation) => {
		evt.preventDefault()
		evt.stopPropagation()
		if (evt.currentTarget.checkValidity() === true) {
			this.setState({validated: false}) // Hide validation styles on a valid form
			mutation({
				variables: {
					customerProfile: createCustomerProfileInput(
						this.state.customerType,
						evt.target.elements
					)
				}
			})
		} else {
			this.setState({validated: true}) // Show validation styles on an invalid form
		}
	}

	render() {
		const profile = this.props.cart.customer || null
		return (
			<Mutation
				mutation={ATTACH_CUSTOMER_PROFILE_MUTATION}
				onCompleted={() => this.props.completeStep(STEP_CUSTOMER)}
				update={(cache, {data: {attachCustomerProfile}}) =>
					updateCartCache(cache, attachCustomerProfile)
				}>
				{(attachCustomerProfile, {loading, error}) => (
					<LoadingState loading={loading}>
						<Form
							id='customer-form'
							noValidate
							validated={this.state.validated}
							onSubmit={(evt) => this.onFormSubmit(evt, attachCustomerProfile)}>
							<Form.Group>
								<CountrySelect
									id='countryCode'
									onChange={this.onCountryChange}
									defaultValue={this.state.selectedCountry}
								/>
							</Form.Group>
							<Form.Group>
								<CustomerTypeSwitch
									value={this.state.customerType}
									onChange={this.onCustomerTypeChange}
								/>
							</Form.Group>
							{this.state.selectedCountry === 'SE' && (
								<Form.Group>
									<TextInput
										id='govIdentifier'
										label={this.getGovIdentifierLabel()}
										pattern={this.getGovIdentifierPattern()}
										placeholder={this.getGovIdentifierPlaceholder()}
										required
										defaultValue={profile && profile.govIdentifier}
									/>
								</Form.Group>
							)}
							{this.state.customerType === CUSTOMER_TYPE_PRIVATE && (
								<Form.Row>
									<Col sm={6}>
										<Form.Group>
											<TextInput
												id='firstName'
												label={this.context.strings.formLabelFirstName}
												pattern='^(?!\s+$).+'
												defaultValue={
													profile && profile.address.name.split(' ')[0]
												}
												required
												validationErrorMessage={
													this.context.strings.formValidationInvalidFirstName
												}
											/>
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group>
											<TextInput
												id='lastName'
												label={this.context.strings.formLabelLastName}
												pattern='^(?!\s+$).+'
												defaultValue={
													profile &&
													profile.address.name.split(' ')[
														profile.address.name.split(' ').length - 1
													]
												}
												required
												validationErrorMessage={
													this.context.strings.formValidationInvalidLastName
												}
											/>
										</Form.Group>
									</Col>
								</Form.Row>
							)}
							{this.state.customerType === CUSTOMER_TYPE_ORGANIZATION && (
								<>
									<Form.Group>
										<TextInput
											id='name'
											label={this.context.strings.formLabelOrganizationName}
											pattern='^(?!\s+$).+'
											defaultValue={profile && profile.address.name}
											required
											validationErrorMessage={
												this.context.strings
													.formValidationInvalidOrganizationName
											}
										/>
									</Form.Group>
									<Form.Group>
										<TextInput
											id='reference'
											label={this.context.strings.formLabelReference}
											defaultValue={profile && profile.reference}
										/>
									</Form.Group>
								</>
							)}
							<Form.Row>
								<Col sm={6}>
									<Form.Group>
										<TextInput
											type='email'
											id='email'
											placeholder={this.context.strings.formPlaceholderEmail}
											validationErrorMessage={
												this.context.strings.formValidationInvalidEmail
											}
											label={this.context.strings.formLabelEmail}
											required
											defaultValue={profile && profile.address.email}
										/>
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group>
										<TextInput
											id='cellphone'
											pattern='^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$'
											placeholder={
												this.context.strings.formPlaceholderCellphone
											}
											label={this.context.strings.formLabelPhone}
											defaultValue={profile && profile.address.cellphone}
											required
											validationErrorMessage={
												this.context.strings.formValidationInvalidPhone
											}
										/>
									</Form.Group>
								</Col>
							</Form.Row>
							<Form.Group>
								<TextInput
									id='addressline1'
									label={this.context.strings.formLabelStreetAddress}
									defaultValue={profile && profile.address.addressline1}
									required
									validationErrorMessage={
										this.context.strings.formValidationInvalidAdress
									}
								/>
							</Form.Group>
							<Form.Row className='mb-2'>
								<Col sm={6}>
									<Form.Group>
										<TextInput
											id='zip'
											pattern='^(.){0,10}$'
											label={this.context.strings.formLabelZip}
											defaultValue={profile && profile.address.zip}
											required
											validationErrorMessage={
												this.context.strings.formValidationInvalidZip
											}
										/>
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group>
										<TextInput
											id='city'
											label={this.context.strings.formLabelCity}
											defaultValue={profile && profile.address.city}
											required
											validationErrorMessage={
												this.context.strings.formValidationInvalidCity
											}
										/>
									</Form.Group>
								</Col>
							</Form.Row>
							<Form.Group>
								<AcceptanceCheckbox
									id='acceptTerms'
									label={this.context.strings.formLabelAcceptTerms}
									defaultChecked={this.props.cart.acceptTerms}
								/>
							</Form.Group>
							<FormErrorAlert error={error} />
							<Button
								size='sm'
								variant='bee-action-primary'
								type='submit'
								disabled={loading}>
								{requiresInstallationProfile(
									this.props.cart,
									this.context.settings.installationProductSku,
									this.context.settings.expressInstallationProductSku,
									this.context.settings.garo.installationProductSku
								)
									? this.context.strings.nextStep +
									  ' – ' +
									  this.context.strings.accordionHeaderInstallation
									: this.context.strings.placeOrder}
							</Button>
						</Form>
					</LoadingState>
				)}
			</Mutation>
		)
	}
}

CustomerProfile.contextType = CheckoutContext

export default CustomerProfile
