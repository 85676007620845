import React, {Component} from 'react'
import Table from 'react-bootstrap/Table'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class CartProductTable extends Component {
	render() {
		return (
			<Table variant='cart'>
				<thead>
					<tr>
						<th className='th-product'>
							{this.context.strings.cartTableHeaderProduct}
						</th>
						<th className='th-quantity'>
							{this.context.strings.cartTableHeaderQuantity}
						</th>
						<th className='th-unit-price'>
							{this.context.strings.cartTableHeaderItemPrice}
						</th>
						{this.props.containsDiscount && (
							<th className='th-total-discount'>
								{this.context.strings.cartTableHeaderItemDiscount}
							</th>
						)}
						<th className='th-total-price'>
							{this.context.strings.cartTableHeaderTotalPrice}
						</th>
					</tr>
				</thead>
				<tbody>{this.props.children}</tbody>
			</Table>
		)
	}
}

CartProductTable.contextType = CheckoutContext

export default CartProductTable
