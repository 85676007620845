import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import {loader} from 'graphql.macro'
import InstallationListItem from 'components/installation/InstallationListItem'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {updateCartCache} from 'utils/Utils'

const UPDATE_CART_MUTATION = loader('queries/updateCart.gql')

export class InstallationList extends Component {
	onChangeHandler = (sku, updateCart) => {
		updateCart({
			variables: {
				sku: sku,
				quantity: 1
			}
		})

		this.props.onSelection()
	}

	getInstallationProductSkus = () =>
		this.props.products.map((product) => product.sku)

	hasInstallItemInCart = () => {
		const installationSkus = this.getInstallationProductSkus()

		return this.props.cart.lineItems.find((lineItem) =>
			installationSkus.includes(lineItem.product.sku)
		)
	}

	itemIsInCart = (sku) => {
		return (
			this.props.cart.lineItems.find(
				(lineItem) => lineItem.product.sku === sku
			) !== undefined
		)
	}

	render() {
		return (
			<ul className='list-unstyled'>
				<Mutation
					mutation={UPDATE_CART_MUTATION}
					update={(cache, {data: {updateCart}}) =>
						updateCartCache(cache, updateCart)
					}>
					{(updateCart, {loading}) => (
						<>
							{this.context.settings.withoutInstallationOption && (
								<InstallationListItem
									key='withoutInstallation'
									product={{
										bundlePrice: 0,
										name: this.context.strings.withoutInstallation,
										sku: 'withoutInstallation',
										description: this.context.strings
											.withoutInstallationDescription
									}}
									onSelect={(sku) => this.onChangeHandler(sku, updateCart)}
									checked={!this.hasInstallItemInCart()}
								/>
							)}

							{this.props.products.map((product) => (
								<InstallationListItem
									key={product.sku}
									product={product}
									checked={this.itemIsInCart(product.sku)}
									onSelect={(sku) => this.onChangeHandler(sku, updateCart)}
								/>
							))}
						</>
					)}
				</Mutation>
			</ul>
		)
	}
}

InstallationList.contextType = CheckoutContext

export default InstallationList
