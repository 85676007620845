import React, {Component} from 'react'
import Alert from 'react-bootstrap/Alert'

export class FormErrorAlert extends Component {
	render() {
		return this.props.error ? (
			<Alert variant='bee-dragonfruit'>
				{this.props.error.graphQLErrors.map(({message}, i) => (
					<span key={i}>{message}</span>
				))}
			</Alert>
		) : null
	}
}

export default FormErrorAlert
