import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class NumberStepper extends Component {
	render() {
		return (
			<InputGroup size='sm' className='number-stepper'>
				<FormControl
					disabled
					type='text'
					value={this.props.quantity + this.context.strings.cartItemCountSuffix}
				/>
				<InputGroup.Append>
					<Button
						variant='outline-bee-dragonfruit'
						onClick={(evt) => this.props.onChange(1)}>
						+
					</Button>
					<Button
						variant='outline-bee-dragonfruit'
						onClick={(evt) => this.props.onChange(-1)}>
						–
					</Button>
				</InputGroup.Append>
			</InputGroup>
		)
	}
}

NumberStepper.contextType = CheckoutContext

export default NumberStepper
