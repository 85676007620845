import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import countries from '@umpirsky/country-list/data/sv/country.json'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class CountrySelect extends Component {
	render() {
		return (
			<>
				<Form.Label>{this.context.strings.formLabelCountry}</Form.Label>
				<Form.Control as='select' {...this.props}>
					{Object.keys(countries).map((index) => {
						return (
							<option key={index} value={index}>
								{countries[index]}
							</option>
						)
					})}
				</Form.Control>
			</>
		)
	}
}

CountrySelect.contextType = CheckoutContext

export default CountrySelect
