import React, {Component} from 'react'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {currencyFormatter} from 'utils/Utils'

export class CartSummary extends Component {
	render() {
		return (
			<dl className='cart-summary'>
				{this.props.cart.serviceFee && (
					<div>
						<dt>{this.context.strings.serviceFee}</dt>
						<dd>
							{currencyFormatter.format(this.props.cart.serviceFee.total)}
						</dd>
					</div>
				)}
				<div>
					<dt>{this.context.strings.cartSummarySubTotal}</dt>
					<dd>{currencyFormatter.format(this.props.cart.subTotal)}</dd>
				</div>
				<div>
					<dt>{this.context.strings.cartSummaryVAT}</dt>
					<dd>{currencyFormatter.format(this.props.cart.vat)}</dd>
				</div>
				<div className='total'>
					<dt>{this.context.strings.cartSummaryTotal}</dt>
					<dd>{currencyFormatter.format(this.props.cart.total)}</dd>
				</div>
			</dl>
		)
	}
}

CartSummary.contextType = CheckoutContext

export default CartSummary
