import React, {Component} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import AccordionHeader from 'components/accordion/AccordionHeader'

export class AccordionCard extends Component {
	render() {
		return (
			<Card>
				<AccordionHeader
					eventKey={this.props.eventKey}
					title={this.props.title}
					badge={this.props.badge}
					active={this.props.accordionState.activeStep === this.props.eventKey}
					enabled={this.props.accordionState.enabledSteps.includes(
						this.props.eventKey
					)}
					completed={this.props.accordionState.completedSteps.includes(
						this.props.eventKey
					)}
					onHeaderClick={this.props.onHeaderClick}
				/>
				<Accordion.Collapse eventKey={this.props.eventKey}>
					<div className='collapse-scroll'>
						<Card.Body>{this.props.children}</Card.Body>
					</div>
				</Accordion.Collapse>
			</Card>
		)
	}
}

export default AccordionCard
