import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import {loader} from 'graphql.macro'
import Button from 'react-bootstrap/Button'
import {CheckoutContext} from 'contexts/CheckoutContext'
import CartProductTable from 'components/cart/CartProductTable'
import CartProductRow from 'components/cart/CartProductRow'
import CartSummary from 'components/cart/CartSummary'
import {STEP_CART} from 'components/accordion/Accordion'
import {updateCartCache} from 'utils/Utils'
import LoadingState from 'components/common/LoadingState'

const UPDATE_CART_MUTATION = loader('queries/updateCart.gql')

export class Cart extends Component {
	render() {
		const orderLineItemsContainsDiscount = this.props.cart.lineItems.find(
			(lineItem) => lineItem.discountTotal
		)
			? true
			: false

		return (
			<Mutation
				mutation={UPDATE_CART_MUTATION}
				update={(cache, {data: {updateCart}}) =>
					updateCartCache(cache, updateCart)
				}>
				{(updateCart, {loading}) => (
					<LoadingState loading={loading}>
						<CartProductTable containsDiscount={orderLineItemsContainsDiscount}>
							{this.props.cart.lineItems.map((lineItem) => (
								<CartProductRow
									containsDiscount={orderLineItemsContainsDiscount}
									key={lineItem.product.sku}
									lineItem={lineItem}
									updateCart={updateCart}
								/>
							))}
						</CartProductTable>
						<CartSummary cart={this.props.cart} />
						<Button
							size='sm'
							variant='bee-action-primary'
							onClick={() => this.props.completeStep(STEP_CART)}>
							{this.context.strings.nextStep +
								' – ' +
								this.context.strings.accordionHeaderPersonalDetails}
						</Button>
					</LoadingState>
				)}
			</Mutation>
		)
	}
}

Cart.contextType = CheckoutContext

export default Cart
