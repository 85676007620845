import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {Mutation} from 'react-apollo'
import {loader} from 'graphql.macro'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {updateCartCache, createInstallationProfileInput} from 'utils/Utils'
import AcceptanceCheckbox from 'components/common/AcceptanceCheckbox'
import TextInput from 'components/common/TextInput'
import {STEP_INSTALLATION_PROFILE} from 'components/accordion/Accordion'
import LoadingState from 'components/common/LoadingState'
import FormErrorAlert from 'components/common/FormErrorAlert'

const ATTACH_INSTALLATION_PROFILE_MUTATION = loader(
	'queries/attachInstallationProfile.gql'
)

export class InstallationProfile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			validated: false,
			greenDeductionEnabled: this.props.cart.installation
				? this.props.cart.installation.greenDeduction
				: false,
			fuseBoxUpgradeEnabled: this.props.cart.installation
				? this.props.cart.installation.fuseBoxUpgrade.enabled
				: false
		}
	}

	onFuseBoxUpgradeEnableChange = (evt) => {
		this.setState({
			fuseBoxUpgradeEnabled: evt.target.checked
		})
	}

	onGreenDeductionChange = (evt) => {
		this.setState({
			greenDeductionEnabled: evt.target.value === 'yes' ? true : false
		})
	}

	onFormSubmit = (evt, mutation) => {
		evt.preventDefault()
		evt.stopPropagation()
		if (evt.currentTarget.checkValidity() === true) {
			this.setState({validated: false}) // Hide validation styles on a valid form
			mutation({
				variables: {
					installationProfile: createInstallationProfileInput(
						evt.target.elements
					)
				}
			})
		} else {
			this.setState({validated: true}) // Show validation styles on an invalid form
		}
	}

	canUseGreenDeduction(greenDeductionBlacklist) {
		for (const lineItem of this.props.cart.lineItems) {
			if (greenDeductionBlacklist.includes(lineItem.product.sku)) {
				return false
			}
		}

		return true
	}

	render() {
		const {strings, settings} = this.context
		const {customer} = this.props.cart
		const profile = this.props.cart.installation || null
		const greenDeductionAvailable = this.canUseGreenDeduction(
			settings.greenDeductionBlacklist
		)

		return (
			<Mutation
				mutation={ATTACH_INSTALLATION_PROFILE_MUTATION}
				onCompleted={() => this.props.completeStep(STEP_INSTALLATION_PROFILE)}
				update={(cache, {data: {attachInstallationProfile}}) =>
					updateCartCache(cache, attachInstallationProfile)
				}>
				{(attachInstallationProfile, {loading, error}) => (
					<LoadingState loading={loading}>
						<Form
							id='installation-form'
							noValidate
							validated={this.state.validated}
							onSubmit={(evt) =>
								this.onFormSubmit(evt, attachInstallationProfile)
							}>
							{customer &&
								customer.type === 'PRIVATE' &&
								greenDeductionAvailable && (
									<div className='green-deduction green-deduction__wrapper'>
										<h5>
											{strings.formLabelInstallationGreenDeductionHeading}
										</h5>
										<p
											dangerouslySetInnerHTML={{
												__html:
													strings.formLabelInstallationGreenDeductionDescription
											}}
										/>

										<Form.Group>
											<Form.Check
												id='greenDeductionYes'
												type='radio'
												name='greenDeduction'
												label={strings.formLabelGreenDeductionYes}
												value='yes'
												required
												onChange={this.onGreenDeductionChange}
											/>
											<Form.Check
												id='greenDeductionNo'
												type='radio'
												name='greenDeduction'
												label={strings.formLabelGreenDeductionNo}
												value='no'
												required
												onChange={this.onGreenDeductionChange}
											/>
										</Form.Group>

										{this.state.greenDeductionEnabled && (
											<Form.Group>
												<Form.Label>{strings.formLabelPropertyType}</Form.Label>
												<Form.Control
													as='select'
													id='propertyType'
													required={this.state.greenDeductionEnabled}>
													<option value=''>
														{strings.formLabelSelectBlankOption}
													</option>
													<option value='TENANT_OWNERSHIP'>
														{strings.tenantOwnership}
													</option>
													<option value='PRIVATE_PROPERTY'>
														{strings.property}
													</option>
												</Form.Control>
												<Form.Control.Feedback type='invalid'>
													{strings.formValidationInvalidPropertyType}
												</Form.Control.Feedback>
											</Form.Group>
										)}

										{this.state.greenDeductionEnabled && (
											<Form.Group>
												<TextInput
													id='propertyIdentifier'
													required={this.state.greenDeductionEnabled}
													label={strings.formLabelPropertyIdentifier}
													defaultValue={profile && profile.propertyIdentifier}
												/>
												<small className='form-text text-muted'>
													<span
														dangerouslySetInnerHTML={{
															__html: strings.formLabelPropertyIdentifierLegend
														}}
													/>
												</small>
											</Form.Group>
										)}
									</div>
								)}

							<h5>{strings.formLabelInstallationDetailsHeading}</h5>
							<Form.Group>
								<TextInput
									id='electricBoxIdentifier'
									pattern='^\d{18}$'
									label={strings.formLabelElectricBoxIdentifier}
									placeholder='735999XXXXXXXXXXXX'
									defaultValue={profile && profile.electricBoxIdentifier}
									validationErrorMessage={
										this.context.strings
											.formValidationInvalidElectricBoxIdentifier
									}
								/>
								<small className='form-text text-muted'>
									{strings.formLabelElectricBoxIdentifierLegend}
								</small>
							</Form.Group>
							<p
								dangerouslySetInnerHTML={{
									__html: strings.formLabelInstallationDetailsBody
								}}
							/>

							<Form.Group className='mb-3'>
								<AcceptanceCheckbox
									id='iOwnTheBuilding'
									label={strings.formLabelIOwnTheBuilding}
									defaultChecked={
										profile && profile.acceptances.iOwnTheBuilding
									}
								/>
								<AcceptanceCheckbox
									id='fulfillsElectric'
									label={strings.formLabelFulfillsElectric}
									defaultChecked={
										profile && profile.acceptances.fulfillsElectric
									}
								/>
								<AcceptanceCheckbox
									id='acceptCostFailedInstall'
									label={strings.formLabelAcceptCostFailedInstall}
									defaultChecked={
										profile && profile.acceptances.acceptCostFailedInstall
									}
								/>
								<AcceptanceCheckbox
									id='acceptAdditionalCosts'
									label={strings.formLabelAcceptAdditionalCosts}
									defaultChecked={
										profile && profile.acceptances.acceptAdditionalCosts
									}
								/>
							</Form.Group>

							<Form.Group>
								<h5>{strings.formLabelBeforeInstallationHeading}</h5>
								<p>{strings.formLabelBeforeInstallationBody}</p>
							</Form.Group>
							<Form.Group>
								<Form.Label>
									{strings.formLabelBeforeInstallationInput}
								</Form.Label>
								<Form.Control
									as='textarea'
									id='comment'
									rows='3'
									defaultValue={profile && profile.comment}
								/>
							</Form.Group>

							<Form.Row>
								<Col sm={6}>
									<Form.Group>
										<Form.Label>{strings.formLabelFuseBoxUpgrade}</Form.Label>
										<Form.Check
											custom
											id='fuseBoxUpgradeEnabled'
											className='mt-2'>
											<Form.Check.Input
												type='checkbox'
												onChange={this.onFuseBoxUpgradeEnableChange}
												defaultChecked={
													profile && profile.fuseBoxUpgrade.enabled
												}
											/>
											<Form.Check.Label>
												{strings.formLabelFuseBoxUpgradeEnable}
											</Form.Check.Label>
										</Form.Check>
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group>
										<Form.Label>
											{strings.formLabelFuseBoxUpgradeSelect}
										</Form.Label>
										<Form.Control
											as='select'
											id='fuseBoxUpgradeOption'
											required={this.state.fuseBoxUpgradeEnabled}>
											<option value=''>
												{strings.formLabelSelectBlankOption}
											</option>
											<option value='UPGRADE_16A_20A'>16A → 20A</option>
											<option value='UPGRADE_20A_25A'>20A → 25A</option>
										</Form.Control>
										<Form.Control.Feedback type='invalid'>
											{strings.formValidationInvalidField}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Form.Row>

							<FormErrorAlert error={error} />

							<Button
								size='sm'
								variant='bee-action-primary'
								type='submit'
								disabled={loading}>
								{strings.placeOrder}
							</Button>
						</Form>
					</LoadingState>
				)}
			</Mutation>
		)
	}
}

InstallationProfile.contextType = CheckoutContext

export default InstallationProfile
