import React, {Component} from 'react'
import {Query} from 'react-apollo'
import {loader} from 'graphql.macro'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InstallationList from 'components/installation/InstallationList'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {STEP_INSTALLATION} from 'components/accordion/Accordion'
import {findProductBySKU} from 'utils/Utils'

const shopQuery = loader('queries/shop.gql')

export class Installation extends Component {
	onSelectionHandler = () => {
		this.props.completeStep(STEP_INSTALLATION)
	}

	getInstallationProducts = (products) => {
		if (this.hasGaroItemInCart()) {
			return products.filter(
				(product) =>
					product.sku === this.context.settings.garo.installationProductSku
			)
		}

		return products.filter((product) => this.isInstallationProduct(product))
	}

	isInstallationProduct = (product) =>
		[
			this.context.settings.installationProductSku,
			this.context.settings.expressInstallationProductSku
		].includes(product.sku)

	hasGaroItemInCart = () =>
		this.props.cart.lineItems.find((lineItem) =>
			this.context.settings.garo.products.includes(lineItem.product.sku)
		)

	formatInstallationProducts(products) {
		const installationProducts = this.getInstallationProducts(products)

		for (const product of installationProducts) {
			// Department allows orders without installation
			if (this.context.settings.withoutInstallationOption) {
				product.bundlePrice = product.basePriceTotal
				continue
			}

			// Calculate difference between normal and express installation
			if (product.sku === this.context.settings.expressInstallationProductSku) {
				const installationProduct = findProductBySKU(
					products,
					this.context.settings.installationProductSku
				)
				const expressInstallationProduct = findProductBySKU(
					products,
					this.context.settings.expressInstallationProductSku
				)

				product.bundlePrice =
					expressInstallationProduct.basePriceTotal -
					installationProduct.basePriceTotal
				continue
			}

			product.bundlePrice = 0
		}

		return installationProducts
	}

	render() {
		return (
			<Query query={shopQuery}>
				{({loading, error, data}) => {
					if (loading || error) return null

					const installationProducts = this.formatInstallationProducts(
						data.products
					)

					return (
						<Row>
							<Col>
								<InstallationList
									cart={this.props.cart}
									products={installationProducts}
									onSelection={this.onSelectionHandler}
								/>
							</Col>
						</Row>
					)
				}}
			</Query>
		)
	}
}

Installation.contextType = CheckoutContext

export default Installation
