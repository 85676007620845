import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class TextInput extends Component {
	render() {
		return (
			<>
				<Form.Label>{this.props.label}</Form.Label>
				<Form.Control
					type={this.props.type || 'text'}
					id={this.props.id}
					pattern={this.props.pattern}
					defaultValue={this.props.defaultValue}
					placeholder={this.props.placeholder}
					required={this.props.required}
				/>
				<Form.Control.Feedback type='invalid'>
					{this.props.validationErrorMessage ||
						this.context.strings.formValidationInvalidField}
				</Form.Control.Feedback>
			</>
		)
	}
}

TextInput.contextType = CheckoutContext

export default TextInput
