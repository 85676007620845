import React, {Component} from 'react'
import {Query} from 'react-apollo'
import {loader} from 'graphql.macro'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import ProductList from 'components/shop/ProductList'
import ProductCategoryFilter from 'components/shop/ProductCategoryFilter'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {STEP_SHOP} from 'components/accordion/Accordion'
import OrderLimitModal from './OrderLimitModal'
import {findProductBySKU} from 'utils/Utils'

const shopQuery = loader('queries/shop.gql')

export const FILTER_VIEW_ALL = 'view_all'

export class Shop extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filterValue: FILTER_VIEW_ALL,
			showOrderLimitModal: false,
			orderLimitProductCategory: null,
			installationProduct: null,
			expressInstallationProduct: null
		}
	}

	onFilterChange = (val) => {
		this.setState({
			filterValue: val
		})
	}

	showOrderLimitModal = (show, productCategory) => {
		this.setState({
			showOrderLimitModal: show,
			orderLimitProductCategory: productCategory
		})
	}

	findGaroInstallationAddons(products) {
		let addons = []

		products.forEach((product) => {
			if (this.context.settings.garo.installationAddons.includes(product.sku)) {
				addons.push(product)
			}
		})

		return addons
	}

	calculateBundleCost(product, installationProduct) {
		return product.basePriceTotal + installationProduct.basePriceTotal
	}

	calculateGaroBundleCost(products, product) {
		const installationProduct = findProductBySKU(
			products,
			this.context.settings.garo.installationProductSku
		)

		const installationAddons = this.findGaroInstallationAddons(products)

		let bundlePrice =
			product.basePriceTotal + installationProduct.basePriceTotal

		installationAddons.forEach((addon) => {
			bundlePrice += addon.basePriceTotal
		})

		return bundlePrice
	}

	formatProducts(products) {
		const installationProduct = findProductBySKU(
			products,
			this.context.settings.installationProductSku
		)

		for (const product of products) {
			// Department allows orders without installation
			if (this.context.settings.withoutInstallationOption) {
				product.bundlePrice = product.basePriceTotal
				continue
			}

			if (this.context.settings.garo.installationAddons.includes(product.sku)) {
				product.bundlePrice = 0
				continue
			}

			if (
				product.sku === this.context.settings.installationProductSku ||
				product.sku === this.context.settings.garo.installationProductSku
			) {
				product.bundlePrice = 0
				continue
			}

			if (product.sku === this.context.settings.expressInstallationProductSku) {
				const expressInstallationProduct = findProductBySKU(
					products,
					this.context.settings.expressInstallationProductSku
				)

				product.bundlePrice =
					expressInstallationProduct.basePriceTotal -
					installationProduct.basePriceTotal
				continue
			}

			if (this.context.settings.garo.products.includes(product.sku)) {
				product.bundlePrice = this.calculateGaroBundleCost(products, product)
				continue
			}

			if (product.installable) {
				product.bundlePrice = this.calculateBundleCost(
					product,
					installationProduct
				)
				continue
			}

			product.bundlePrice = product.basePriceTotal
		}

		return products
	}

	render() {
		return (
			<Query
				query={shopQuery}
				onCompleted={(data) => this.props.onShopQueryCompleted(data)}>
				{({loading, error, data}) => {
					if (loading || error) return null
					const FORMATTED_PRODUCTS = this.formatProducts(data.products)

					const INSTALLATION_PRODUCT = findProductBySKU(
						FORMATTED_PRODUCTS,
						this.context.settings.installationProductSku
					)
					const EXPRESS_INSTALLATION_PRODUCT = findProductBySKU(
						FORMATTED_PRODUCTS,
						this.context.settings.expressInstallationProductSku
					)
					const GARO_INSTALLATION_PRODUCT = findProductBySKU(
						FORMATTED_PRODUCTS,
						this.context.settings.garo.installationProductSku
					)
					const GARO_INSTALLATION_ADDONS = this.findGaroInstallationAddons(
						FORMATTED_PRODUCTS
					)
					return (
						<>
							<Row>
								<Col>
									<ProductCategoryFilter
										filterValue={this.state.filterValue}
										productCategories={data.productCategories}
										onChange={this.onFilterChange}
									/>
									<Button
										size='sm'
										variant='bee-action-primary'
										className='float-right'
										disabled={!this.props.cart.lineItems.length}
										onClick={() => this.props.completeStep(STEP_SHOP)}>
										{this.context.strings.viewCart}
										{this.props.cart.lineItems.length > 0 && (
											<Badge className='ml-2 badge-circular' variant='light'>
												{this.props.cart.lineItems.length}
											</Badge>
										)}
									</Button>
								</Col>
							</Row>
							<Row>
								<Col>
									<ProductList
										cart={this.props.cart}
										products={FORMATTED_PRODUCTS}
										showOrderLimitModal={this.showOrderLimitModal}
										installationProduct={INSTALLATION_PRODUCT}
										expressInstallationProduct={EXPRESS_INSTALLATION_PRODUCT}
										garoInstallationProduct={GARO_INSTALLATION_PRODUCT}
										garoInstallationAddons={GARO_INSTALLATION_ADDONS}
										filterValue={this.state.filterValue}
									/>
								</Col>
							</Row>
							<OrderLimitModal
								show={this.state.showOrderLimitModal}
								productCategory={this.state.orderLimitProductCategory}
								onHide={() => this.showOrderLimitModal(false)}
							/>
						</>
					)
				}}
			</Query>
		)
	}
}

Shop.contextType = CheckoutContext

export default Shop
