import React, {Component} from 'react'
import ProductListItem from 'components/shop/ProductListItem'
import {FILTER_VIEW_ALL} from 'components/shop/Shop'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class ProductList extends Component {
	render() {
		return (
			<ul className='list-unstyled'>
				{this.props.products
					.filter((product) => {
						return (
							product !== this.props.installationProduct &&
							product !== this.props.expressInstallationProduct &&
							product !== this.props.garoInstallationProduct &&
							!this.props.garoInstallationAddons.includes(product) &&
							(this.props.filterValue === FILTER_VIEW_ALL ||
								product.category.id === this.props.filterValue)
						)
					})
					.map((product) => (
						<ProductListItem
							key={product.sku}
							product={product}
							showOrderLimitModal={this.props.showOrderLimitModal}
							cart={this.props.cart}
							inCart={
								this.props.cart.lineItems.find(
									(lineItem) => lineItem.product.sku === product.sku
								) !== undefined
							}
						/>
					))}
			</ul>
		)
	}
}

ProductList.contextType = CheckoutContext

export default ProductList
