import {loader} from 'graphql.macro'

const CART_QUERY = loader('queries/cart.gql')

//TODO: If all cart mutations would return cart with the same key we could simplify the calls to this method
export function updateCartCache(cache, updatedCart) {
	cache.writeQuery({
		query: CART_QUERY,
		data: {cart: updatedCart}
	})
}

export function findProductBySKU(products, sku) {
	return products.find((product) => product.sku === sku)
}

export function canAddToCart(cart, product) {
	if (!product.category.limitQuantity) return true
	return cart.lineItems.find((lineItem) => {
		return lineItem.product.category.id === product.category.id
	})
		? false
		: true
}

export function hasInstallableLineItems(cart) {
	return cart.lineItems.find((lineItem) => {
		return lineItem.product.installable
	})
		? true
		: false
}

export function requiresInstallationProfile(
	cart,
	installationProductSku,
	expressInstallationProductSku,
	garoInstallationProductSku
) {
	return cart.lineItems.find((lineItem) => {
		return (
			lineItem.product.sku === installationProductSku ||
			lineItem.product.sku === expressInstallationProductSku ||
			lineItem.product.sku === garoInstallationProductSku
		)
	})
		? true
		: false
}

export function createInstallationProfileInput(elements) {
	let installationProfile = {greenDeduction: false}

	elements.greenDeduction &&
		(installationProfile.greenDeduction =
			elements.greenDeduction.value === 'yes' ? true : false)
	elements.propertyIdentifier &&
		(installationProfile.propertyIdentifier = elements.propertyIdentifier.value)
	elements.propertyType &&
		(installationProfile.propertyType = elements.propertyType.value)
	elements.electricBoxIdentifier.value &&
		(installationProfile.electricBoxIdentifier =
			elements.electricBoxIdentifier.value)
	installationProfile.fuseBoxUpgrade = {}
	installationProfile.fuseBoxUpgrade.enabled =
		elements.fuseBoxUpgradeEnabled.checked
	elements.fuseBoxUpgradeOption.value &&
		(installationProfile.fuseBoxUpgrade.option =
			elements.fuseBoxUpgradeOption.value)
	installationProfile.comment = elements.comment.value
	installationProfile.acceptances = {}
	installationProfile.acceptances.iOwnTheBuilding =
		elements.iOwnTheBuilding.checked
	installationProfile.acceptances.fulfillsElectric =
		elements.fulfillsElectric.checked
	installationProfile.acceptances.acceptCostFailedInstall =
		elements.acceptCostFailedInstall.checked
	installationProfile.acceptances.acceptAdditionalCosts =
		elements.acceptAdditionalCosts.checked

	return installationProfile
}

export function createCustomerProfileInput(type, elements) {
	let customerProfile = {}
	customerProfile.type = type
	elements.reference &&
		elements.reference.value &&
		(customerProfile.reference = elements.reference.value)
	elements.govIdentifier &&
		(customerProfile.govIdentifier = elements.govIdentifier.value)
	customerProfile.address = {}
	customerProfile.address.name = elements.firstName
		? elements.firstName.value + ' ' + elements.lastName.value
		: elements.name.value
	customerProfile.address.email = elements.email.value
	customerProfile.address.cellphone = elements.cellphone.value
	customerProfile.address.addressline1 = elements.addressline1.value
	customerProfile.address.city = elements.city.value
	customerProfile.address.zip = elements.zip.value
	customerProfile.address.countryCode = elements.countryCode.value
	return customerProfile
}

export const currencyFormatter = new Intl.NumberFormat('sv-SE', {
	style: 'currency',
	currency: 'SEK',
	minimumFractionDigits: 0
})
