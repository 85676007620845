import React, {Component} from 'react'
import Form from 'react-bootstrap/Form'
import {CheckoutContext} from 'contexts/CheckoutContext'

export class AcceptanceCheckbox extends Component {
	render() {
		return (
			<Form.Check custom id={this.props.id} className='mb-2'>
				<Form.Check.Input
					required
					type='checkbox'
					defaultChecked={this.props.defaultChecked}
				/>
				<Form.Check.Label
					dangerouslySetInnerHTML={{__html: this.props.label}}
				/>
				<Form.Control.Feedback type='invalid'>
					{this.context.strings.formValidationAcceptanceNotChecked}
				</Form.Control.Feedback>
			</Form.Check>
		)
	}
}

AcceptanceCheckbox.contextType = CheckoutContext

export default AcceptanceCheckbox
