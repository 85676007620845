import React, {Component} from 'react'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {Mutation} from 'react-apollo'
import {loader} from 'graphql.macro'
import {CheckoutContext} from 'contexts/CheckoutContext'
import {updateCartCache, currencyFormatter, canAddToCart} from 'utils/Utils'

const UPDATE_CART_MUTATION = loader('queries/updateCart.gql')

export class ProductListItem extends Component {
	getInstallationString() {
		return this.context.settings.withoutInstallationOption
			? this.context.strings.excludingInstallation
			: this.context.strings.includingInstallation
	}

	render() {
		return (
			<Media as='li' className='media-product-list-item'>
				<img src={this.props.product.imageUrl} alt={this.props.product.name} />
				<Media.Body>
					<h5>{this.props.product.name}</h5>
					{this.props.product.description && (
						<div className='product-description'>
							{this.props.product.description}
						</div>
					)}
					<div className='product-price'>
						{currencyFormatter.format(this.props.product.bundlePrice)}
						{this.props.product.installable && (
							<small className='installation-charge'>
								({this.getInstallationString()})
							</small>
						)}
					</div>
					<Mutation
						mutation={UPDATE_CART_MUTATION}
						update={(cache, {data: {updateCart}}) =>
							updateCartCache(cache, updateCart)
						}>
						{(updateCart, {loading}) => (
							<>
								<Button
									size='sm'
									variant='bee-action-primary'
									disabled={this.props.inCart || loading}
									className={this.props.inCart && 'in-cart'}
									onClick={() =>
										canAddToCart(this.props.cart, this.props.product)
											? updateCart({
													variables: {
														sku: this.props.product.sku,
														quantity: 1
													}
											  })
											: this.props.showOrderLimitModal(
													true,
													this.props.product.category
											  )
									}>
									{!loading && this.props.inCart
										? this.context.strings.addedToCart
										: this.props.product.installable
										? this.context.strings.addToCartWithoutInstallation
										: this.context.strings.addToCart}
									{loading && (
										<Spinner
											as='span'
											className='ml-2'
											animation='grow'
											size='sm'
											variant='bee-concrete'
										/>
									)}
								</Button>
							</>
						)}
					</Mutation>
				</Media.Body>
			</Media>
		)
	}
}

ProductListItem.contextType = CheckoutContext

export default ProductListItem
