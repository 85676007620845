import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import {CheckoutContext} from 'contexts/CheckoutContext'
import NumberStepper from 'components/common/NumberStepper'
import {currencyFormatter} from 'utils/Utils'

export class CartProductRow extends Component {
	canChangeQuantity() {
		const {lineItem} = this.props
		const {settings} = this.context

		if (lineItem.product.category.limitQuantity) {
			return false
		}

		if (lineItem.product.sku === settings.installationProductSku) {
			return false
		}

		if (lineItem.product.sku === settings.expressInstallationProductSku) {
			return false
		}

		if (lineItem.product.sku === settings.garo.installationProductSku) {
			return false
		}

		if (settings.garo.installationAddons.includes(lineItem.product.sku)) {
			return false
		}

		return true
	}

	canRemoveLineItem() {
		const {lineItem} = this.props
		const {settings} = this.context

		if (lineItem.removable === false) {
			return false
		}

		if (lineItem.product.sku === settings.garo.installationProductSku) {
			return false
		}

		if (settings.garo.installationAddons.includes(lineItem.product.sku)) {
			return false
		}

		return true
	}

	render() {
		const canChangeQuantity = this.canChangeQuantity()
		const canRemoveLineItem = this.canRemoveLineItem()

		return (
			<tr>
				<td className='td-product'>
					<h5>{this.props.lineItem.product.name}</h5>
					<p>{this.props.lineItem.product.description}</p>
					{canRemoveLineItem === true && (
						<Button
							variant='link'
							size='sm'
							className='pl-0'
							onClick={() => {
								this.props.updateCart({
									variables: {
										sku: this.props.lineItem.product.sku,
										quantity: -this.props.lineItem.quantity
									}
								})
							}}>
							{this.context.strings.removeFromCart}
						</Button>
					)}
				</td>

				<td className='td-quantity'>
					{canChangeQuantity === true && (
						<NumberStepper
							quantity={this.props.lineItem.quantity}
							onChange={(quantity) =>
								this.props.updateCart({
									variables: {
										sku: this.props.lineItem.product.sku,
										quantity: quantity
									}
								})
							}
						/>
					)}

					{canChangeQuantity === false && <p>1 st</p>}
				</td>
				<td className='td-unit-price'>
					{currencyFormatter.format(this.props.lineItem.unitPriceInclVat)}
				</td>
				{this.props.containsDiscount && (
					<td className='td-total-discount'>
						{this.props.lineItem.discountTotal > 0 &&
							'-' + currencyFormatter.format(this.props.lineItem.discountTotal)}
					</td>
				)}
				<td className='td-total-price'>
					{currencyFormatter.format(this.props.lineItem.total)}
				</td>
			</tr>
		)
	}
}

CartProductRow.contextType = CheckoutContext

export default CartProductRow
