import React from 'react'
import ReactDOM from 'react-dom'
import {init} from '@sentry/browser'
import 'assets/styles/index.scss'
import App from 'components/App'
import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV === 'production') {
	init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.NODE_ENV
	})
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
